import { cn } from '@finn/ui-utils';
import React from 'react';

import RawHtml from '~/components/RawHtml';
import { BaseCosmicObject } from '~/types/cosmicModules';
import { getPromoBannerHtml } from '~/utils/plp/plp';

type Props = {
  pageData: BaseCosmicObject<any>;
  isPdp?: boolean;
  className?: string;
};

const PromoBanner = ({ pageData, isPdp, className }: Props) => {
  const bannerHtmlMobile = getPromoBannerHtml(pageData, isPdp, true);
  const bannerHtmlDesktop = getPromoBannerHtml(pageData, isPdp, false);

  if (!bannerHtmlMobile && !bannerHtmlDesktop) return null;

  return (
    <div className="mb-4 md:mb-0">
      <div className="block md:hidden">
        <RawHtml
          html={bannerHtmlMobile || bannerHtmlDesktop}
          className={cn(className, 'min-h-2.5 [&_a]:border-b-0')}
          useGlobalStyles
          fullWidthMedia
        />
      </div>
      <div className="hidden md:block">
        <RawHtml
          html={bannerHtmlDesktop || bannerHtmlMobile}
          className={cn(className, 'min-h-2.5 [&_a]:border-b-0')}
          useGlobalStyles
          fullWidthMedia
        />
      </div>
    </div>
  );
};

export default PromoBanner;
